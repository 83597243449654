import RecentOrders from "component/RecentOrders";
import Header from "component/Header"
import RecentTransactions from "component/RecentTransactions";
import Sidebar from "component/Sidebar"
import UserProfileCard from "component/UserProfileCard"
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchUserById } from "services/adminService";

const UserDetail = () => {
    const param = useParams();

    const [user, setUser] = useState()

    useEffect(() => {
        console.log('id = ', param.id)
        fetchUserById(param.id).then(res => {
            setUser(res)
        })
    }, [param.id])

    const reloadData = () => {
        fetchUserById(param.id).then(res => {
            setUser(res)
        })
    }

    return (<>
        <Header />
        <div className="bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8 mb-10">
                <UserProfileCard customer={user} reloadCallback={reloadData} />
                <RecentOrders recentOrders={user?.recentOrders} />
                <RecentTransactions recentTransactions={user?.recentTransactions} />
            </div>
        </div>
    </>)
}
export default UserDetail