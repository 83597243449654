import Header from "component/Header";
import Sidebar from "component/Sidebar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { deleteVoucher, fetchVoucherById, updateVoucher } from "services/dishService";

import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { getImagePathNotReplace, showErrorNotification, showSuccessNotification, USDollar } from "utils/utils";
import { Timestamp } from "firebase/firestore";

const VoucherDetail = () => {
    const param = useParams();

    const [voucher, setVoucher] = useState()
    const [isUpdateMode, setIsUpdateMode] = useState(false)
    const [endDate, setEndDate] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        console.log('id = ', param.id)

        fetchVoucherById(param.id).then(res => {
            setVoucher(res)
            console.log('voucher = ', res)
        })

    }, [param.id])


    const handleChange = (e) => {
        const { name, value } = e.target;
        // Check if the name belongs to a nested property
        setVoucher({
            ...voucher,
            [name]: value,
        });
    };


    const handleChangeDate = (ev) => {
        if (!ev.target['validity'].valid) return;
        const dateString = ev.target['value'];
        const dt = new Date(dateString + "T00:00:00");
         const timestamp = Timestamp.fromDate(new Date(dt))
        setEndDate(ev.target['value']);
        setVoucher({
            ...voucher,
            expiredAt: timestamp
        });
    }

    const handleDeleteVoucher = (e) => {
        e.preventDefault();
        console.log('delete voucher')
        deleteVoucher(param.id).then(res => {
            showSuccessNotification('Voucher deleted successfully')
            console.log('delete voucher res = ', res)
            navigate('/admin/voucher')
        })
    }

    const handleSave = (e) => {
        e.preventDefault();
        if (voucher.coupon === '') {
            alert("Coupon must not be empty.");
            return;
        }

        if (isNaN(voucher.couponValue)) {
            alert("Coupon value must be a number.");
            return;
        }

        if (voucher.couponType === 'PERCENT' && (voucher.couponValue < 1 || voucher.couponValue > 100)) {
            alert("Wrong percent for coupon value.");
            return;
        }
        if (voucher.couponType === 'DIRECT' && voucher.couponValue < 0) {
            alert("Wrong coupon value.");
            return;
        }

        console.log('voucher = ', voucher)

        updateVoucher(voucher).then(voucherId => {
            //show dialog to inform voucher created successfully, and an action to navigate to order detail page
            showSuccessNotification('Voucher update successfully')
            setIsUpdateMode(false)
        }).catch(error => {
            showErrorNotification('Error updating voucher: ', error)
            console.error('Error updating voucher: ', error)
        }
        )
    };
    return (<>
        <Header />
        <div className="bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8 mb-10">

                {voucher && <form>
                    {voucher.isDeleted && (
                        <div className="flex items-center text-red-600">
                            <img src={getImagePathNotReplace('assets/deleted.png')} alt="Deleted"
                                className="w-[200px] h-[200px] object-contain" />
                        </div>
                    )}
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="flex justify-between items-center">
                                <h2 className="text-base/7 font-semibold text-gray-900">Voucher Information of {voucher.id}</h2>
                                <div>

                                    {!isUpdateMode &&
                                        <button
                                            onClick={(e) => { e.preventDefault(); setIsUpdateMode(true) }}
                                            className="ml-2 px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Edit</button>}

                                    <button
                                        onClick={handleDeleteVoucher}
                                        className="ml-2 px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                        Delete</button>
                                </div>
                            </div>
                            <div>
                                <img src={voucher.imageUrl} alt={voucher.coupon}
                                    className="w-40 h-40 rounded-md mt-4" />
                            </div>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8">
                                <div className="sm:col-span-3">
                                    <label htmlFor="coupon" className="block text-sm/6 font-medium text-gray-900">
                                        Coupon
                                    </label>
                                    <div className="mt-2">
                                        <input id="coupon" name="coupon"
                                            readOnly={!isUpdateMode}
                                            value={voucher.coupon}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="title" className="block text-sm/6 font-medium text-gray-900">
                                        Voucher title
                                    </label>
                                    <div className="mt-2">
                                        <input id="title" name="title"
                                            readOnly={!isUpdateMode}
                                            value={voucher.title}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="content" className="block text-sm/6 font-medium text-gray-900">
                                        Content
                                    </label>
                                    <div className="mt-2">
                                        <input id="coupon" name="coupon"
                                            readOnly={!isUpdateMode}
                                            value={voucher.content}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="couponType" className="block text-sm/6 font-medium text-gray-900">
                                        Type
                                    </label>
                                    <div className="mt-2">
                                        {isUpdateMode ?
                                            <>
                                                <select id="couponType" name="couponType" className=" p-2 border rounded-md"
                                                    onChange={handleChange}>
                                                    <option value={'DIRECT'}>DIRECT</option>
                                                    <option value={'PERCENT'}>PERCENT</option>
                                                </select>
                                                <ChevronDownIcon
                                                    aria-hidden="true"
                                                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                />
                                            </>
                                            :
                                            <input id="couponType" name="couponType"
                                                readOnly={!isUpdateMode}
                                                value={voucher.couponType}
                                                onChange={handleChange}
                                                type="text"
                                                className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                        }
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="couponValue" className="block text-sm/6 font-medium text-gray-900">
                                        Coupon Value
                                    </label>
                                    <div className="mt-2">
                                        {!isUpdateMode ?
                                            <span className="bg-gray-300 col-start-1 row-start-1 w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                                                {voucher.couponType === 'DIRECT' ? `${USDollar.format(voucher.couponValue)}` : `${voucher.couponValue}%`}
                                            </span>
                                            :
                                            <input id="couponValue" name="couponValue"
                                                readOnly={!isUpdateMode}
                                                value={voucher.couponValue}
                                                onChange={handleChange}
                                                type="text"
                                                className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                        }
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="expiredAt" className="block text-sm/6 font-medium text-gray-900">
                                        Expired At
                                    </label>
                                    <div className="mt-2 grid grid-cols-1">
                                        {
                                            isUpdateMode ?
                                                <>
                                                    <input type="date"
                                                        className=" p-2 border rounded-md"
                                                        value={endDate}
                                                        onChange={handleChangeDate} />

                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                    />
                                                </>
                                                : <span className="bg-gray-300 col-start-1 row-start-1 w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                                                    {voucher?.expiredAt ? new Timestamp(voucher?.expiredAt?.seconds, voucher?.expiredAt?.nanoseconds).toDate().toLocaleString() : ''}</span>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {isUpdateMode && <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm/6 font-semibold text-gray-900"
                            onClick={(e) => { e.preventDefault(); setIsUpdateMode(false) }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleSave}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>}
                </form>}

            </div>
        </div >
    </>)
}
export default VoucherDetail
