import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { subscribeOrderAllStore, subscribeOrderByStore, updateOrderStatus } from 'services/orderService';
import { useNavigate } from 'react-router-dom';
import NewOrderModal from 'component/NewOrderModal';
import { getLastestOrderFromList, isToday, USDollar } from 'utils/utils';
import { Howl, Howler } from 'howler';
import audioSrc from '../../assets/bell.mp3'
import CustomizedSwitches from 'component/IOSSwitch';

const OrderTable = () => {

    const { selectedStore, findStoreTitleByAddress } = useContext(AuthContext)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orders, setOrders] = useState([]);
    const [newOrder, setNewOrder] = useState(null); // State để lưu thông tin của order mới cập nhật
    const [isModalOpen, setIsModalOpen] = useState(false); // State để kiểm soát hiển thị của modal
    const [soundEnabled, setSoundEnabled] = useState(false);

    var silentSound = new Howl({
        src: [audioSrc],
        autoplay: false,
        loop: false,
        volume: 0.0,
        onend: function () {
            console.log('Finished!');
        },
        onplayerror: function () {
            console.log('onplayerror!');

            // silentSound.once('unlock', function () {
            //     silentSound.play();
            // });
        }
    });

    const navigate = useNavigate()

    useEffect(() => {
        Howler.stop();
        let unsubscribe;
        if (selectedStore) {
            if (selectedStore === 'all') {
                unsubscribe = subscribeOrderAllStore(rowsPerPage, (ordersList) => {
                    handleOrderUpdate(ordersList);
                });
            } else {
                // Subscribe to orders and get real-time updates
                unsubscribe = subscribeOrderByStore(selectedStore.address, rowsPerPage, (ordersList) => {
                    handleOrderUpdate(ordersList);
                });
            }
        }

        // Clean up the subscription when the component unmounts or selectedStore changes
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [selectedStore, rowsPerPage]);

    const handleOrderUpdate = (ordersList) => {
        if (ordersList.length > 0) {
            const latestOrder = getLastestOrderFromList(ordersList);
            console.log(latestOrder)
            if (latestOrder !== null && isToday(latestOrder.createdAt) && latestOrder.orderStatus === 'NEW') {
                setNewOrder(latestOrder);
                setIsModalOpen(true);
                console.log('New order:', latestOrder);
            } else {
                console.log('No new order:', latestOrder);
            }
        }
        setOrders(ordersList);
    };

    const handleStatusChange = async (e, order) => {
        const newStatus = e.target.value;
        console.log(`Order ID: ${order.id}, New Status: ${newStatus}`);

        try {
            // Call service to update order status (this function should be defined in your service file)
            await updateOrderStatus(order, newStatus);
            // No need to manually setOrders here since onSnapshot will update automatically

        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };

    const handleTestBell = () => {
        setIsModalOpen(true);
        setNewOrder(orders[0]);
    }

    const handleChangeLimit = (e) => {
        setRowsPerPage(Number(e.target.value))
    }


    const getStatusColor = (status) => {
        switch (status) {
            case "NEW":
            case "PROCESS":
            case "DELIVERY":
                return "text-blue-600";
            case "COMPLETED":
                return "text-green-600";
            case "CANCELLED":
                return "text-red-600";
            default:
                return "text-gray-600"; // Default color for unknown statuses
        }
    };

    const doEnableSound = () => {
        Howler.volume(0);
        if (!soundEnabled) {
            silentSound.play();
        }
        setSoundEnabled(!soundEnabled);
    }

    return (
        <>
            <NewOrderModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} newOrder={newOrder} />
            {!soundEnabled && (
                <div className='flex justify-center p-10 bg-orange-200'>
                    <CustomizedSwitches
                        label={'ENABLE NOTIFICATION FOR EVERY NEW ORDER'}
                        checked={soundEnabled}
                        fontSize={'20px'}
                        onChange={doEnableSound} />
                </div >
            )}
            <div className={`bg-white shadow rounded-lg p-4 overflow-x-auto ${!soundEnabled ? 'blur-sm' : ''}`}>
                <div className="flex justify-between items-center mb-4">
                    <div>
                        {/* <span className={`${soundEnabled ? 'text-green-600' : 'text-red-600'} text-xl`}>**Enable sound notification</span> */}
                        <CustomizedSwitches
                            label={'ENABLE NOTIFICATION FOR EVERY NEW ORDER'}
                            fontSize={'14px'}
                            checked={soundEnabled}
                            onChange={doEnableSound} />
                    </div>
                    <div>
                        <button className='bg-orange-600 text-white rounded-md px-3 py-2 text-sm font-medium'
                            onClick={handleTestBell}
                        >
                            Try the store bell
                        </button>
                        <select className="ml-4 p-2 border rounded-md"
                            onChange={handleChangeLimit}>
                            <option value={5}>5 queries</option>
                            <option value={10}>10 queries</option>
                            <option value={20}>20 queries</option>
                            <option value={50}>50 queries</option>
                        </select>
                    </div>
                </div>
                <table className="w-full text-left">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="p-3 font-medium text-gray-700">ORDER AT</th>
                            <th className="p-3 font-medium text-gray-700">STORE ORDERED</th>
                            <th className="p-3 font-medium text-gray-700">CUSTOMER NAME</th>
                            <th className="p-3 font-medium text-gray-700">ITEM COUNT</th>
                            <th className="p-3 font-medium text-gray-700">TOTAL AMOUNT</th>
                            <th className="p-3 font-medium text-gray-700">ORDER STATUS</th>
                            <th className="p-3 font-medium text-gray-700">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50" onClick={() => navigate(order.id)}>
                                <td className="p-3">{order.createdAt ? new Date(order.createdAt).toLocaleString() : ''}</td>
                                <td className="p-3">{findStoreTitleByAddress(order.cart.shopAddress)}</td>
                                <td className="p-3">{order.customerModel?.name}</td>
                                <td className="p-3">{order.cart.orderLines?.reduce((total, line) => total + (line.itemCount || 0), 0)} items</td>
                                <td className="p-3">{USDollar.format(order.cart.totalAmount)}</td>
                                <td className={`p-3 font-semibold ${getStatusColor(order.orderStatus)}`}>
                                    {order.orderStatus}
                                </td>
                                <td className="p-3 text-gray-500">
                                    <select className="ml-4 p-2 border rounded-md"
                                        onChange={(e) => handleStatusChange(e, order)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from propagating to the row
                                    >
                                        <option value='NEW'>Set as NEW</option>
                                        <option value='PROCESS'>Set as PROCESS</option>
                                        <option value='DELIVERY'>Set as DELIVERY</option>
                                        <option value='COMPLETED'>Set as COMPLETED</option>
                                        <option value='CANCELLED'>Set as CANCELLED</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div >

        </>
    );
}
export default OrderTable