import { useEffect, useState } from 'react';
import { fetchTransactions } from 'services/adminService';
import Pagination from 'component/Pagination';
import { USDollar } from 'utils/utils';
import { useNavigate } from 'react-router';

const TransactionTable = () => {

    const navigate = useNavigate()

    const [transactions, setTransactions] = useState()

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDateChange = () => {
        setCurrentPage(1);
        fetchTransactions(currentPage, lastVisibleStack, rowsPerPage, startDate, endDate).then(res => {
            if (currentPage > lastVisibleStack.length) {
                setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
            }
            setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
            setTransactions(res.rows);
        });
    };

    useEffect(() => {
        fetchTransactions(currentPage, lastVisibleStack, rowsPerPage).then(res => {
            // Store the last document in the stack for future reference
            if (currentPage > lastVisibleStack.length) {
                setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
            }
            // Check if there is a next page
            setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
            setTransactions(res.rows)
        })
    }, [currentPage, rowsPerPage, lastVisibleStack]);


    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setCurrentPage(1)
        setRowsPerPage(Number(e.target.value))
    }

    return (
        <div className="bg-white shadow rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
                <span>
                    From
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                            setStartDate(e.target.value)
                            console.log(e.target.value)
                        }}
                        className="p-2 border rounded-md"
                    />
                    To
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="ml-2 p-2 border rounded-md"
                    />
                    <button
                        onClick={handleDateChange}
                        className="ml-2 p-2 bg-blue-500 text-white rounded-md"
                    >
                        Apply
                    </button>
                    {(startDate || endDate) && (
                        <button
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setLastVisibleStack([]);
                                handleDateChange();
                            }}
                            className="ml-2 p-2 bg-gray-500 text-white rounded-md"
                        >
                            Clear
                        </button>
                    )}
                </span>

                <select
                    onChange={handleChangeLimit}
                    className="ml-4 p-2 border rounded-md">
                    <option value={10}>10 queries</option>
                    <option value={20}>20 queries</option>
                    <option value={50}>50 queries</option>
                </select>
            </div>
            <table className="w-full text-left">
                <thead>
                    <tr className="bg-gray-100 border-b">
                        <th className="p-3 font-medium text-gray-700">ID</th>
                        <th className="p-3 font-medium text-gray-700">User ID</th>
                        <th className="p-3 font-medium text-gray-700">Name</th>
                        <th className="p-3 font-medium text-gray-700">Email</th>
                        <th className="p-3 font-medium text-gray-700">Type</th>
                        <th className="p-3 font-medium text-gray-700 text-end">Amount</th>
                        <th className="p-3 font-medium text-gray-700">Created On</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions?.map((row, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50">
                            <td className="p-3">#{row.id.substring(0, 5)}</td>
                            <td className="p-3">#{row.uid.substring(0, 5)}</td>
                            <td className="p-3  hover:text-blue-400 text-blue-700"
                                onClick={() => navigate(`/admin/user/${row.uid}`)}
                            >{row?.userInfo?.name}</td>
                            <td className="p-3">{row?.userInfo?.email}</td>
                            <td className="p-3">{row.type}{row.createdBy == null ? '' : ' (by ADMIN)'}</td>
                            <td className={`p-3 ${row.amount > 0 ? 'bg-green-100' : 'bg-red-100'} text-end`}>{USDollar.format(row.amount / 100)}</td>
                            <td className="p-3">{new Date(row.created * 1000).toLocaleString() ?? ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                isNextPageAvailable={isNextPageAvailable}
            />
        </div>
    );
}
export default TransactionTable