import { getFirestore, collection, getDocs, orderBy, limit, query, where, getDoc, doc, startAfter, startAt, Timestamp, updateDoc, addDoc } from 'firebase/firestore';
import { app } from '../services/firebase';
import { saveTransactionBunniesReference } from './orderService';

const db = getFirestore(app);

const RATING_BUNNY_REWARD_AMOUNT = 100;

export const fetchRating = async (currentPage, lastVisibleStack, rowsPerPage, sortColumn = 'createdAt', sortOrder = 'desc') => {
    const cl = collection(db, 'reviews');
    let mQuery;
    if (currentPage === 1) {
        // Fetch the first page
        mQuery = query(cl, orderBy(sortColumn, sortOrder), limit(rowsPerPage));
    } else if (currentPage > lastVisibleStack.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAfter(lastVisible), limit(rowsPerPage));
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAt(prevLastVisible), limit(rowsPerPage));
    }

    // const searchField = isEmail(searchInput) ? "email" : "name";

    // if (searchInput) {
    //     mQuery = query(cl,
    //         where(searchField, '>=', searchInput),
    //         where(searchField, '<', searchInput + '\uf8ff'),
    //         // orderBy(sortColumn, sortOrder),
    //         limit(usersPerPage)
    //     );
    // }

    const snapshot = await getDocs(mQuery);

    const rows = await Promise.all(snapshot.docs.map(async (rowData) => {
        const customerDataDoc = await getDoc(doc(db, 'users', rowData.data().customerId));
        const customerData = customerDataDoc.exists() ? customerDataDoc.data() : null;

        return {
            ...rowData.data(),
            id: rowData.id,
            customerData,
        };
    }));

    console.log('reviews = ', rows)
    return { snapshot, rows };

}

export const approveRating = async (rating, adminUID) => {
    try {
        // Update the review with 'accepted' status
        const ratingRef = doc(db, 'reviews', rating.id);
        await updateDoc(ratingRef, {
            accepted: true,
            acceptedAdminId: adminUID,
            updatedAt: Timestamp.now(),
        });

        // Get bunny transaction related to the review
        const bunnyTransactionCollection = collection(db, 'bunnies')
        const mQuery = query(bunnyTransactionCollection,
            where('sourceRatingId', '==', rating.id),
            where('status', '!=', 'RETURNED'),
            limit(1)
        );

        const bunnyTransactionSnapshot = await getDocs(mQuery)
        if (bunnyTransactionSnapshot.docs.length === 1) {
            // Update the existing bunny transaction
            const bunnyTransactionRef = bunnyTransactionSnapshot.docs[0].ref;
            await updateDoc(bunnyTransactionRef, {
                amount: RATING_BUNNY_REWARD_AMOUNT,
                status: 'RETURNED',
                updated_at: Timestamp.now(),
            });

            console.log(`rating.customerModel.uid = ${rating.customerModel.uid}`);

            // Save the transaction reference
            await saveTransactionBunniesReference(RATING_BUNNY_REWARD_AMOUNT, bunnyTransactionRef.id, rating.customerId);
        } else {
            // Create a new bunny transaction if none exists
            const newTransactionRef = await createNewBunnyTransactionForRating(bunnyTransactionCollection, rating, RATING_BUNNY_REWARD_AMOUNT);
            // Save the transaction reference
            await saveTransactionBunniesReference(RATING_BUNNY_REWARD_AMOUNT, newTransactionRef.id, rating.customerId);
        }
    } catch (error) {
        console.error('Error approving rating:', error);
    }
};

const createNewBunnyTransactionForRating = async (bunnyTransactionCollection, rating, amount) => {
    const transactionBunnies = {
        amount: amount,
        uid: rating.customerId,
        type: 'REVIEW',
        status: 'RETURNED',
        sourceOrderId: rating.orderId,
        sourceDishId: rating.dishId,
        sourceRatingId: rating.id,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
    };

    // Add a new bunny transaction document
    // const newTransactionCollection = await collection(db, 'bunnies');
    const newTransactionRef = await addDoc(bunnyTransactionCollection, transactionBunnies);

    // Update the newly added transaction with the generated ID
    await updateDoc(newTransactionRef, {
        id: newTransactionRef.id,
    });
    return newTransactionRef
}

export const sendGiftBunnyFromAdmin = async (adminUID, customerUID, giftAmount) => {
    try {
        const bunnyTransactionCollection = collection(db, 'bunnies')
        // Create a new bunny transaction 
        const newTransactionRef = await createNewBunnyTransactionForGiftFromAdmin(bunnyTransactionCollection, customerUID, giftAmount, adminUID);
        // Save the transaction reference
        await saveTransactionBunniesReference(giftAmount, newTransactionRef.id, customerUID);
    } catch (error) {
        console.error('Error approving rating:', error);
    }
};

const createNewBunnyTransactionForGiftFromAdmin = async (bunnyTransactionCollection, customerId, amount, adminUID) => {
    const transactionBunnies = {
        amount: amount,
        uid: customerId,
        type: 'GIFT',
        status: 'RETURNED',
        sourceDishId: null,
        sourceOrderId: null,
        sourceRatingId: null,
        sourceReferralId: null,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        updated_by: adminUID,
    };

    // Add a new bunny transaction document
    // const newTransactionCollection = await collection(db, 'bunnies');
    const newTransactionRef = await addDoc(bunnyTransactionCollection, transactionBunnies);

    // Update the newly added transaction with the generated ID
    await updateDoc(newTransactionRef, {
        id: newTransactionRef.id,
    });
    return newTransactionRef
}