import AuthContext from "context/AuthContext";
import { useContext, useState } from "react";
import { getImagePath, USDollar } from "utils/utils";
import PickDishModal from "./PickDishModal";
import { v4 as uuidv4 } from 'uuid';
import ToppingPickModal from "./ToppingPickModal";
import { createOrderFromAdmin } from "services/orderService";
import OrderCreateSuccessModal from "./OrderCreateSuccessModal";

const OrderCreateForm = () => {

    const { user, listStore } = useContext(AuthContext)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalToppingOpen, setIsModalToppingOpen] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [selectingOrderLine, setSelectingOrderLine] = useState(null);
    const [orderCreatedId, setOrderCreatedId] = useState('');

    const [order, setOrder] = useState({
        customerModel: {
            name: '',
            address: null,
            phone: null,
            uid: user.uid
        },
        cart: {
            bunniesRedeem: null,
            bunniesRedeemAmount: 0,
            deliveryAddress: null,
            deliveryType: 'PICKUP',
            orderLines: [],
            promotionAmount: 0,
            promotionCoupon: null,
            promotionId: null,
            promotionType: null,
            promotionValue: null,
            shopAddress: '',
            shopPhoneNumber: '',
            subTotalAmount: 0,
            totalAmount: 0,
            vatAmount: 0
        },
        id: null,
        orderStatus: 'NEW',
        createdAt: new Date().toISOString(),
        created_at: new Date().toISOString(),
        createdBy: user.uid,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check if the name belongs to a nested property
        if (name.startsWith('customerModel.')) {
            const nestedKey = name.split('.')[1]; // Extract the key, e.g., 'name'
            setOrder((prevOrder) => ({
                ...prevOrder,
                customerModel: {
                    ...prevOrder.customerModel,
                    [nestedKey]: value,
                },
            }));
        } else if (name.startsWith('cart.')) {
            const nestedKey = name.split('.')[1]; // Extract the key, e.g., 'name'
            setOrder((prevOrder) => ({
                ...prevOrder,
                cart: {
                    ...prevOrder.cart,
                    [nestedKey]: value,
                },
            }));
        } else {
            setOrder({
                ...order,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (order.customerModel.name === '') {
            alert("Please enter customer name.");
            return;
        }

        if (order.cart.shopAddress === '') {
            alert("Please choose store address.");
            return;
        }

        if (order.cart.orderLines.length === 0) {
            alert("Please add at least 1 dish to order.");
            return;
        }

        //no transaction
        //no payment

        console.log('order = ', order)
        createOrderFromAdmin(order).then(orderId => {
            //show dialog to inform order created successfully, and an action to navigate to order detail page
            setOrderCreatedId(orderId)
            setIsModalSuccessOpen(true)
        })
    };

    const getOptionsString = (options) => {
        var result = [];
        if (options && Object.keys(options).length > 0) {
            for (const [key, value] of Object.entries(options)) {
                result.push(`${key}: ${value}`)
            }
            return result
        }
    }
    const handleAddDish = () => {
        console.log(order)
        setIsModalOpen(true)
    }

    const handleChangeItemCount = (e) => {
        const { name, value } = e.target;
        console.log('name= ', name)
        console.log('isExist in cart ', order.cart.orderLines.find(item => item.id === name))
        //find item in order.cart.orderLines with id = name, then set the itemCount = value

        const newOrderLines = order.cart.orderLines.map((item) => {
            if (item.id === name) {
                return {
                    ...item,
                    itemCount: parseInt(value),
                }
            } else {
                return item;
            }
        });
        const newAmount = calculateAmount(newOrderLines);


        setOrder((prevOrder) => ({
            ...prevOrder,
            cart: {
                ...prevOrder.cart,
                orderLines: newOrderLines,
                ...newAmount,
            }
        }));
    }

    const handleSelectDish = (dish) => {

        const orderLine = {
            customOptions: {},
            dishCategoryId: dish.categoryId,
            dishId: dish.id,
            dishName: dish.name,
            modifications: dish.modifications,
            dishSize: dish.variationName,
            id: `[#${uuidv4().substring(0, 5)}]`,
            imageUrl: 'assets/temp/' + dish.imageUrl,
            itemCount: 1,
            price: dish.price,
            requireOptions: {},
            reviewId: null
        }
        const newOrderLines = [...order.cart.orderLines, orderLine];
        const newAmount = calculateAmount(newOrderLines);


        setOrder((prevOrder) => ({
            ...prevOrder,
            cart: {
                ...prevOrder.cart,
                orderLines: newOrderLines,
                ...newAmount,
            }
        }));
        setIsModalOpen(false)
    }

    const calculateAmount = (newOrderLines) => {
        return {
            subTotalAmount: newOrderLines.reduce((acc, item) => acc + (item.price * item.itemCount), 0),
            totalAmount: (newOrderLines.reduce((acc, item) => acc + (item.price * item.itemCount), 0) * 1.07),
            vatAmount: (newOrderLines.reduce((acc, item) => acc + (item.price * item.itemCount), 0) * 0.07),
        }
    }

    const onRemoveItem = (item) => {
        const newOrderLines = order.cart.orderLines.filter((i) => i.id !== item.id);
        const newAmount = calculateAmount(newOrderLines);

        setOrder((prevOrder) => ({
            ...prevOrder,
            cart: {
                ...prevOrder.cart,
                orderLines: newOrderLines,
                ...newAmount,
            }
        }));

    }

    const handleChooseTopping = (e, orderline) => {
        e.preventDefault();
        setSelectingOrderLine(orderline)
        setIsModalToppingOpen(true)
    }

    const handleSaveTopping = (res) => {
        const { requireOptions, customOptions, orderLinePrice } = res;
        const newOrderLines = order.cart.orderLines.map((item) => {
            if (item.id === selectingOrderLine.id) {
                console.log('updating item = ', item.id)
                return {
                    ...item,
                    requireOptions: requireOptions,
                    customOptions: customOptions,
                    price: orderLinePrice
                }
            } else {
                return item;
            }
        });
        const newAmount = calculateAmount(newOrderLines);


        setOrder((prevOrder) => ({
            ...prevOrder,
            cart: {
                ...prevOrder.cart,
                orderLines: newOrderLines,
                ...newAmount,
            }
        }));

        setSelectingOrderLine(null)
        setIsModalToppingOpen(false)
    }

    return (
        <>
            <OrderCreateSuccessModal isOpen={isModalSuccessOpen} orderCreatedId={orderCreatedId} onClose={() => setIsModalSuccessOpen(false)} />
            <PickDishModal isOpen={isModalOpen} onSelectDish={(dish) => handleSelectDish(dish)} onClose={() => setIsModalOpen(false)} />
            <ToppingPickModal isOpen={isModalToppingOpen} selectingOrderline={selectingOrderLine} onSave={(res) => { handleSaveTopping(res) }} onClose={() => setIsModalToppingOpen(false)} />
            <form className="p-4 bg-white shadow-md rounded-lg">

                <div className="grid grid-cols-2 gap-4">
                    <div className="sm:col-span-2">
                        <h2 className="text-base/7 font-semibold text-gray-900">Personal Information</h2>
                    </div>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Customer Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="customerModel.name"
                            value={order.customerModel.name}
                            onChange={handleChange}
                            className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                    </div>

                    <div className="mt-5 sm:col-span-2">
                        <h2 className="text-base/7 font-semibold text-gray-900">Order Information</h2>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
                            Select store to order
                        </label>
                        <select className="w-full p-2 mt-2 border rounded-md"
                            name='cart.shopAddress'
                            onChange={handleChange}>
                            <option value="">Select a store</option>
                            {listStore?.map((store, index) => (
                                <option key={index} value={store?.address}>{store?.title}, {store?.address}</option>
                            ))}
                        </select>
                    </div>


                    <div className="sm:col-span-2">
                        <button type="button"
                            className="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => handleAddDish()}
                        >
                            + Add Dish
                        </button>
                    </div>
                    <div className="sm:col-span-2">
                        {order?.cart?.orderLines?.map((item, index) => (
                            <div key={index} className="flex justify-between items-center mb-4">
                                <div className="flex items-center">
                                    <img src={getImagePath(`${item.imageUrl}`)} alt={item.dishName} className="w-16 h-16 rounded-md mr-4" />
                                    <div>
                                        <p className="text-gray-800 font-semibold">{item.itemCount}x {item.dishName}</p>
                                        <p className="text-gray-600 text-sm">{item.dishSize}</p>
                                        {item.requireOptions && getOptionsString(item.requireOptions)?.map((rOption, index) =>
                                            <p key={index} className="text-gray-600 text-sm">{rOption}</p>
                                        )}
                                        {item.customOptions && getOptionsString(item.customOptions)?.map((cOption, index) =>
                                            <p key={index} className="text-gray-600 text-sm">{cOption}</p>
                                        )}
                                        <p className="text-gray-800 font-medium">{USDollar.format(item.price)}</p>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    {(item.modifications && item.modifications.length > 0) && <button
                                        className="text-gray-500 font-semibold border border p-2 rounded-md focus:outline-none mr-4"
                                        onClick={(e) => handleChooseTopping(e, item)}
                                    >
                                        Choose toppings
                                    </button>}
                                    <div className="flex items-center min-w-[100px]">
                                        <select className="w-full p-2 border rounded-md"
                                            name={item.id}
                                            defaultValue={item.itemCount}
                                            onChange={handleChangeItemCount}
                                        >
                                            {Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
                                                <option key={i} value={i}>{i} item{i === 1 ? '' : 's'}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <button onClick={() => { onRemoveItem(item) }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ms-4" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                        <h4 className="text-gray-800 font-semibold mt-4">Order Summary</h4>
                        <div className="flex justify-between text-gray-800 mt-2">
                            <span>Sub total</span>
                            <span>{USDollar.format(order.cart.subTotalAmount)}</span>
                        </div>
                        <div className="flex justify-between text-gray-800">
                            <span>TAX</span>
                            <span>{USDollar.format(order.cart.vatAmount)}</span>
                        </div>
                        <div className="flex justify-between text-gray-800 mt-4 border-t pt-4 font-semibold">
                            <span>Total</span>
                            <span>{USDollar.format(order.cart.totalAmount)}</span>
                        </div>

                    </div>

                </div>
            </form>
            <div className="flex justify-center mt-4">
                <button type="button"
                    className="px-5 py-2.5 text-m font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={handleSubmit}
                >
                    Create Order
                </button>
            </div>
        </>
    );
};

export default OrderCreateForm;