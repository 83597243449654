import React, { useEffect, useRef } from "react";
import prevent from '../assets/prevent.mp4'

const PreventSleep = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        const playVideo = () => {
            if (video) {
                video.muted = true; // Ensure it's muted (required for iOS)
                video.loop = true;
                video.play()
                    .then(() => console.log("✅ Video is playing to keep screen awake"))
                    .catch((error) => console.error("🚨 Autoplay failed:", error));
            }
        };

        // Attempt to play video immediately
        playVideo();

        // iOS Safari requires user interaction in some cases
        const handleUserInteraction = () => {
            playVideo();
            document.removeEventListener("touchstart", handleUserInteraction);
            document.removeEventListener("click", handleUserInteraction);
        };

        // Add event listeners in case autoplay fails
        document.addEventListener("touchstart", handleUserInteraction);
        document.addEventListener("click", handleUserInteraction);

        return () => {
            document.removeEventListener("touchstart", handleUserInteraction);
            document.removeEventListener("click", handleUserInteraction);
        };
    }, []);

    return (
        <video
            ref={videoRef}
            playsInline
            muted
            loop
            style={{ display: "none" }} // Hide the video
        >
            <source src={prevent} type="video/mp4" />
        </video>
    );
};

export default PreventSleep;
