import Header from "component/Header";
import Sidebar from "component/Sidebar";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { showErrorNotification, showSuccessNotification } from "utils/utils";
import { Timestamp } from "firebase/firestore";
import AuthContext from "context/AuthContext";
import { uploadImage } from "services/fileService";
import { createVoucher } from "services/dishService";

const VoucherCreate = () => {
    const param = useParams();
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();

    // const [voucher, setVoucher] = useState()
    const [isUpdateMode, setIsUpdateMode] = useState(true)
    const [endDate, setEndDate] = useState('');
    const [uploading, setUploading] = useState(false);


    const [voucher, setVoucher] = useState({
        content: '',
        coupon: '',
        couponType: 'DIRECT',
        couponValue: 0,
        imageUrl: '',
        title: '',
        id: null,
        isDeleted: false,
        expiredAt: Timestamp.fromDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        createdBy: user.uid,
    });

    useEffect(() => {
        console.log('id = ', param.id)

        // fetchVoucherById(param.id).then(res => {
        //     setVoucher(res)
        //     console.log('voucher = ', res)
        // })

    }, [param.id])

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check if the name belongs to a nested property
        setVoucher({
            ...voucher,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (voucher.coupon === '') {
            alert("Coupon must not be empty.");
            return;
        }

        if (isNaN(voucher.couponValue)) {
            alert("Coupon value must be a number.");
            return;
        }

        if (voucher.couponType === 'PERCENT' && (voucher.couponValue < 1 || voucher.couponValue > 100)) {
            alert("Wrong percent for coupon value.");
            return;
        }
        if (voucher.couponType === 'DIRECT' && voucher.couponValue < 0) {
            alert("Wrong coupon value.");
            return;
        }

        console.log('voucher = ', voucher)

        createVoucher(voucher).then(voucherId => {
            //show dialog to inform voucher created successfully, and an action to navigate to order detail page
            showSuccessNotification('Voucher created successfully')
            navigate(`/admin/voucher/${voucherId}`, { replace: true });
        }).catch(error => {
            showErrorNotification('Error creating voucher: ', error)
            console.error('Error creating voucher: ', error)
        }
        )
    };

    const handleChangeDate = (ev) => {
        if (!ev.target['validity'].valid) return;
        const dateString = ev.target['value'];
        const dt = new Date(dateString + "T00:00:00");
        const timestamp = Timestamp.fromDate(new Date(dt))
        setEndDate(ev.target['value']);
        setVoucher({
            ...voucher,
            expiredAt: timestamp
        });
    }
    
    const handleFileUpload = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";

        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (!file) return;

            setUploading(true); // Show loading state

            try {
                // Use ImageService to upload the image
                const imageUrl = await uploadImage(file, "public/vouchers/");
                setVoucher({ ...voucher, imageUrl });
            } catch (error) {
                console.error("Error uploading image:", error);
            } finally {
                setUploading(false);
            }
        };

        input.click();
    };
    return (<>
        <Header />
        <div className="bg-gray-100 flex">
            <Sidebar />
            <div className="flex-1 p-8 mb-10">

                {voucher && <form>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="flex justify-between items-center">
                                <h2 className="text-base/7 font-semibold text-gray-900">Create New Voucher</h2>
                                {!isUpdateMode &&
                                    <div>
                                        <button
                                            onClick={(e) => { e.preventDefault(); setIsUpdateMode(true) }}
                                            className="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Edit
                                        </button>
                                    </div>}
                            </div>
                            <div>
                                <div className="mt-4">
                                    <label htmlFor="imageUrl" className="block text-sm/6 font-medium text-gray-900">
                                        Image URL
                                    </label>
                                    <div className="mt-2 flex-2 items-center">
                                        {isUpdateMode && (
                                            <button
                                                type="button"
                                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={handleFileUpload}
                                                disabled={uploading}
                                            >
                                                {uploading ? "Uploading..." : "Upload Image"}
                                            </button>
                                        )}
                                    </div>

                                    <input id="imageUrl" name="imageUrl"
                                        readOnly={!isUpdateMode}
                                        value={voucher.imageUrl}
                                        onChange={handleChange}
                                        type="text"
                                        className={`mt-4 w-full ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />

                                </div>
                                {voucher.imageUrl && <img src={voucher.imageUrl} alt={voucher.coupon}
                                    className="w-40 h-40 rounded-md mt-4" />}
                            </div>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8">
                                <div className="sm:col-span-3">
                                    <label htmlFor="coupon" className="block text-sm/6 font-medium text-gray-900">
                                        Coupon
                                    </label>
                                    <div className="mt-2">
                                        <input id="coupon" name="coupon"
                                            readOnly={!isUpdateMode}
                                            value={voucher.coupon}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="title" className="block text-sm/6 font-medium text-gray-900">
                                        Voucher title
                                    </label>
                                    <div className="mt-2">
                                        <input id="title" name="title"
                                            readOnly={!isUpdateMode}
                                            value={voucher.title}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="content" className="block text-sm/6 font-medium text-gray-900">
                                        Content
                                    </label>
                                    <div className="mt-2">
                                        <input id="content" name="content"
                                            readOnly={!isUpdateMode}
                                            value={voucher.content}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="couponType" className="block text-sm/6 font-medium text-gray-900">
                                        Type
                                    </label>
                                    <div className="mt-2">
                                        {isUpdateMode ?
                                            <>
                                                <select id="couponType" name="couponType" className=" p-2 border rounded-md"
                                                    onChange={handleChange}>
                                                    <option value={'DIRECT'}>DIRECT</option>
                                                    <option value={'PERCENT'}>PERCENT</option>
                                                </select>
                                                <ChevronDownIcon
                                                    aria-hidden="true"
                                                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                />
                                            </>
                                            :
                                            <input id="couponType" name="couponType"
                                                readOnly={!isUpdateMode}
                                                value={voucher.couponType}
                                                onChange={handleChange}
                                                type="text"
                                                className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                        }
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="price" className="block text-sm/6 font-medium text-gray-900">
                                        Price
                                    </label>
                                    <div className="mt-2">

                                        <input id="couponValue" name="couponValue"
                                            readOnly={!isUpdateMode}
                                            value={voucher.couponValue}
                                            onChange={handleChange}
                                            type="text"
                                            className={`block w-full  ${isUpdateMode ? '' : 'bg-gray-300'} rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="expiredAt" className="block text-sm/6 font-medium text-gray-900">
                                        Expired At
                                    </label>
                                    <div className="mt-2 grid grid-cols-1">
                                        {
                                            isUpdateMode ?
                                                <>
                                                    <input type="date"
                                                        className=" p-2 border rounded-md"
                                                        value={endDate}
                                                        onChange={handleChangeDate} />

                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                    />
                                                </>
                                                : <span className="bg-gray-300 col-start-1 row-start-1 w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                                                    {voucher?.expiredAt ? new Timestamp(voucher?.expiredAt?.seconds, voucher?.expiredAt?.nanoseconds).toDate().toLocaleString() : ''}</span>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {isUpdateMode && <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm/6 font-semibold text-gray-900"
                            onClick={() => navigate('/admin/voucher')}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>}
                </form>}

            </div>
        </div>
    </>)
}
export default VoucherCreate
