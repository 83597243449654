import Sidebar from "component/Sidebar"
import Header from "component/Header"
import VoucherTable from "./VoucherTable"

const VoucherManage = () => (
    <>
        <Header root={true} />
        <div className="min-h-screen bg-gray-100 flex flex-col lg:flex-row">
            <Sidebar />
            <div className="flex-1 p-4 md:p-8">
                <div className="w-full h-full bg-gray-200 border-dashed border-4 border-gray-300">
                    <VoucherTable />
                </div>
            </div>
        </div>
    </>
)

export default VoucherManage