import { Link } from "react-router-dom";
import logo from '../assets/ic_logo_1102.png'
import { useContext, useEffect, useState } from "react";
import AuthContext from "context/AuthContext";
import { fetchRemoteStores } from "services/adminService";


const superMenus = [
    { name: "Order History", path: '/admin/order-history' },
    { name: "Create Order", path: '/admin/order/create' },
    { name: "Dish Manage", path: '/admin/dish' },
    { name: "Voucher Manage", path: '/admin/voucher' },
    { name: "User Manage", path: '/admin/user' },
    { name: "Transactions", path: '/admin/transaction' },
    { name: "Rating Manage", path: '/admin/rating' },
]
const menus = [
    // { name: "Dish", path: '/admin/dish' },
    // { name: " Category", path: '/category' },
    // { name: "Voucher", path: '/voucher' },
    { name: "Order", path: '/admin/order' },
    // { name: "Rating", path: '/rating' },
    // { name: "Banner", path: '/banner' },
    // { name: "Banner Bottom", path: '/banner-bottom' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Sidebar = ({reloadStore = false}) => {

    const { user, selectedStore, doSelectStore, listStore, saveListStore } = useContext(AuthContext)

    const [stores, setStores] = useState(() => {
        return listStore ? Array.from(listStore) : [];
    });

    useEffect(() => {
        if (!listStore || !!reloadStore) {
            const fetchStores = async () => {
                try {
                    const storesList = await fetchRemoteStores(user)
                    setStores(storesList);
                    console.log('fetched data from remote')
                    saveListStore(storesList)
                } catch (error) {
                    console.error("Error fetching stores: ", error);
                }
            };
            fetchStores();
        } else {
            console.log('load data from local')
        }
    }, [ ]);

    const handleStoreChange = (event) => {
        if (event.target.value === 'all') {
            doSelectStore('all')
        } else {
            const selectedStore = stores.find(store => store.title === event.target.value);
            console.log('selected : ', selectedStore)
            doSelectStore(selectedStore)
        }
    };

    return (
        <>
            <div className="max-lg:hidden w-64 bg-white border-r border-gray-200">
                <div className="flex items-center py-6 px-6 border-b border-gray-200">
                    <img src={logo} alt="Logo" className="items-center w-auto" />
                </div>

                <div className="mt-8 px-6">
                    <h3 className="text-sm text-gray-600 uppercase tracking-wider">Select Store</h3>
                    <select className="w-full p-2 mt-2 border rounded-md"
                        value={typeof selectedStore === 'string' ? selectedStore : selectedStore?.title}
                        onChange={handleStoreChange}>
                        <option value="">Select a store</option>
                        {stores?.map((store, index) => (
                            <option key={index} value={store?.title}>{store?.title}, {store?.address}</option>
                        ))}
                        {user.isSuper && <option key="all" value="all">Select all</option>}
                    </select>
                </div>

                {menus.map((menu) =>
                    <Link key={menu.name} to={menu.path}>
                        <div className="mt-4 px-6">
                            <p href="#"
                                className={classNames(
                                    window.location.pathname === menu.path ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600',
                                    'flex items-center')}>
                                {menu.name}
                            </p>
                        </div>
                    </Link>
                )}

                {user.isSuper && superMenus.map((menu) =>
                    <Link key={menu.name} to={menu.path}>
                        <div className="mt-4 px-6">
                            <p href="#"
                                className={classNames(
                                    window.location.pathname === menu.path ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600',
                                    'flex items-center')}>
                                {menu.name}
                            </p>
                        </div>
                    </Link>
                )}

            </div>
        </>
    );
}
export default Sidebar