import { fireStorage } from "./firebase"; // Import Firebase storage
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

/**
 * Uploads an image to Firebase Storage and returns the download URL.
 * @param {File} file - The image file to upload.
 * @param {string} folder - The folder path in Firebase Storage (default: "uploads/").
 * @returns {Promise<string>} - The download URL of the uploaded image.
 */
export const uploadImage = (file, folder = "public/") => {
    return new Promise((resolve, reject) => {
        if (!file) return reject(new Error("No file selected"));

        // Create a reference in Firebase Storage
        const storageRef = ref(fireStorage, `${folder}${file.name}`);

        // Upload file
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Optional: track upload progress
                console.log(
                    `Upload Progress: ${Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    )}%`
                );
            },
            (error) => {
                console.error("Upload failed:", error);
                reject(error);
            },
            async () => {
                // Get download URL after upload completes
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                console.log("Uploaded Image URL:", downloadURL);
                resolve(downloadURL);
            }
        );
    });
};