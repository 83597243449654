import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";

let navigate;

function setNavigate(navFunction) { navigate = navFunction; }

function getNavigate() { return navigate; }

function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return null;
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const validateCardNumber = (number) => {
    number = number.replace(/\s/g, '');
    const regex = /^[0-9]{16}$/;
    if (!regex.test(number)) return "Card number must be 19 digits.";
    // Luhn Algorithm for credit card validation
    let sum = 0;
    for (let i = 0; i < number.length; i++) {
        let digit = parseInt(number[i]);
        if (i % 2 === number.length % 2) {
            digit *= 2;
            if (digit > 9) digit -= 9;
        }
        sum += digit;
    }
    if (sum % 10 !== 0) return "Invalid card number.";
    return null;
};

const validateExpirationDate = (date) => {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/; // MM/YY format
    if (!regex.test(date)) return "Expiration date must be in MM/YY format.";
    const [month, year] = date.split("/").map((val) => parseInt(val));
    const now = new Date();
    const currentYear = parseInt(now.getFullYear().toString().slice(-2));
    const currentMonth = now.getMonth() + 1;
    if (year < currentYear || (year === currentYear && month < currentMonth))
        return "Card is expired.";
    return null;
};

const validateCVV = (cvv) => {
    const regex = /^[0-9]{3}$/;
    if (!regex.test(cvv)) return "CVV must be 3 digits.";
    return null;
};

const validateCardHolder = (name) => {
    if (!name.trim()) return "Card holder name is required.";
    if (!/^[a-zA-Z\s]+$/.test(name))
        return "Card holder name must contain only letters.";
    return null;
};

const validateColor = (color) => {
    console.log(color);
    if (!color) return "Please select a color.";
    return null;
};

const validateSize = (size) => {
    if (!size) return "Please select a size.";
    return null;
};

const validateRating = (rating) => {
    if (rating === 0) return "Please rating.";
    return null;
};

const validateComment = (commnet) => {
    if (commnet.length < 10) return "Comments should contain at least 10 characters";
    return null;
};

const buildShippingInfo = (shippingInfo = {}) => ({
    phoneNumber: shippingInfo?.phoneNumber ?? "",
    customerName: `${shippingInfo?.firstName ?? ""} ${shippingInfo?.lastName ?? ""}`,
    shippingAddress: `${shippingInfo?.address ?? ""} ${shippingInfo?.apt ?? ""} ${shippingInfo?.zipCode ?? ""} ${shippingInfo?.city ?? ""} ${shippingInfo?.state ?? ""} ${shippingInfo?.country ?? ""}`,
});

const formatNumber = (number) => {
    return formatMoney(number);
};

const formatMoney = (number) => {
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // Toggle for 12-hour or 24-hour format
    });
};

const getLastestOrderFromList = (ordersList) => {
    if (ordersList.length > 0) {
        const latestOrder = ordersList.reduce((latest, order) => {
            if (order.createdAt instanceof Timestamp) {
                return order.createdAt.toDate() > latest.createdAt.toDate() ? order : latest;
            } else if (order.createdAt instanceof String || typeof order.createdAt === 'string') {
                return new Date(order.createdAt) > new Date(latest.createdAt) ? order : latest;
            } else {
                return null;
            }
        }
            , ordersList[0]);
        return latestOrder;
    }
    return null;
};

const isToday = (firebaseTimestamp) => {
    let createdDate;
    if ((firebaseTimestamp instanceof Timestamp)) {
        createdDate = firebaseTimestamp.toDate(); // Chuyển đổi Firebase Timestamp thành JavaScript Date
    } else if (firebaseTimestamp instanceof String || typeof firebaseTimestamp === 'string') {
        createdDate = new Date(firebaseTimestamp);
    } else {
        return false;
    }

    const today = new Date();

    // So sánh ngày, tháng, năm của createdDate với ngày hôm nay
    return createdDate.getDate() === today.getDate() &&
        createdDate.getMonth() === today.getMonth() &&
        createdDate.getFullYear() === today.getFullYear();
};

const isEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
};

const images = require.context('assets/temp', false, /\.(png|webp)$/);
const imagesAsset = require.context('assets', false, /\.(png|webp)$/);

const getImagePath = (imageName) => {
    const webp = imageName.replace(/\.[^.]+$/, '.webp');
    try {
        return images(`${webp}`);
    } catch (err) {
        console.error('Image not found:', webp);
        return null;
    }
};

const getImagePathNotReplace = (imageName) => {
    const webp = imageName
    try {
        return imagesAsset(`${webp}`);
    } catch (err) {
        console.error('Image not found:', webp);
        return null;
    }
};

const showSuccessNotification = (msg) => {
    toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
    });
};

const showErrorNotification = (msg) => {
    toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
    });
};

export {
    setNavigate,
    getNavigate,
    formatPhoneNumber,
    validateCardNumber,
    validateExpirationDate,
    validateCVV,
    validateCardHolder,
    validateColor,
    validateSize,
    buildShippingInfo,
    formatNumber,
    formatDateTime,
    formatMoney,
    validateComment,
    validateRating,
    getLastestOrderFromList,
    isToday,
    isEmail,
    USDollar,
    getImagePath,
    getImagePathNotReplace,
    showSuccessNotification,
    showErrorNotification,
};