import React, { useEffect, useRef } from 'react';
import bell from '../assets/bell.gif'
import audioSrc from '../assets/bell.mp3'
import { USDollar } from 'utils/utils';
import { Howl, Howler } from 'howler';


const NewOrderModal = ({ isOpen, onClose, newOrder }) => {
    const audioRef = useRef(null);
    var sound = new Howl({
        src: [audioSrc],
        autoplay: isOpen,
        loop: isOpen,
        volume: 0.5,
        onend: function () {
            console.log('Finished!');
        },
        onplayerror: function () {
            sound.once('unlock', function () {
                sound.play();
            });
        }
    });


    useEffect(() => {
        if (isOpen) {
            // Play the audio when the modal is open
            // if (audioRef.current) {
            //     audioRef.current.play().catch((error) => {
            //         console.error("Audio play failed:", error);
            //     });
            // }
            sound.play();
            Howler.volume(1);
        } else {
            // Pause the audio when the modal is closed
            // if (audioRef.current) {
            //     audioRef.current.pause();
            //     audioRef.current.currentTime = 0; // Reset audio to start
            // }
            Howler.stop();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                                        <img className="size-10 text-red-600" src={bell} alt="loading..." />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3 className="text-4xl font-semibold text-gray-900" id="modal-title">NEW ORDER</h3>
                                        {newOrder &&
                                            <div className="mt-10 mb-10">
                                                <p className="text-2xl"><strong>Order ID:</strong> {newOrder.id}</p>
                                                <p className="text-2xl mt-4"><strong>Status:</strong> {newOrder.orderStatus}</p>
                                                <p className="text-2xl mt-4"><strong>Customer:</strong> {newOrder.customerModel?.name}</p>
                                                <p className="text-2xl mt-4"><strong>Total Amount:</strong> {USDollar.format(newOrder.cart.totalAmount)}</p>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <audio ref={audioRef} loop>
                                <source src={audioSrc} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button"
                                    onClick={onClose}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewOrderModal;
