import { getFirestore, collection, getDocs, orderBy, limit, query, startAfter, startAt, where, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { app } from '../services/firebase';

const db = getFirestore(app);


export const fetchDishes = async (currentPage, lastVisibleStack, rowsPerPage, searchInput, categoryId = '') => {
    const cl = collection(db, 'dishes');

    let baseQuery = query(cl, orderBy('categoryId', 'asc'), orderBy('order', 'asc'), orderBy('id', 'asc'));

    if (currentPage === 1) {
        baseQuery = query(baseQuery, ...(rowsPerPage > 0 ? [limit(rowsPerPage)] : []));
    } else if (currentPage > lastVisibleStack.length && lastVisibleStack.length > 0) {
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        baseQuery = query(baseQuery, startAfter(lastVisible), limit(rowsPerPage));
    } else if (currentPage > 1 && lastVisibleStack.length >= currentPage - 1) {
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        baseQuery = query(baseQuery, startAt(prevLastVisible), limit(rowsPerPage));
    }

    if (searchInput.trim() !== '') {
        baseQuery = query(
            cl,
            where('name', '>=', searchInput),
            where('name', '<', searchInput + '\uf8ff'),
            orderBy('name', 'asc'), // Order results based on the search field
            limit(rowsPerPage)
        );
    }
    if (categoryId.trim() !== '') {
        baseQuery = query(baseQuery, where('categoryId', '==', categoryId));
    }

    const snapshot = await getDocs(baseQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            _id: doc.id
        }
    });
    console.log('rows = ', rows)
    return { snapshot, rows };

}

export const fetchDishByRefId = async (dishRefId) => {
    const cl = doc(db, 'dishes', dishRefId);
    try {
        const docSnap = await getDoc(cl);
        if (docSnap.exists()) {
            return {
                ...docSnap.data(),
                _id: dishRefId
            }
        } else {
            return null;
        }
    } catch (err) {
        console.log('fetchDishByRefId : ', err)
        return null
    }
}

export const fetchDishById = async (dishId) => {
    const cl = collection(db, 'dishes');

    const mQuery = query(cl,
        where('id', '==', dishId),
        limit(1));

    const snapshot = await getDocs(mQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            _id: doc.id
        }
    });
    console.log('dish = ', rows[0])
    return rows[0];

}


export const fetchCategories = async () => {
    const cl = collection(db, 'category');
    const mQuery = query(cl,
        where('isDeleted', '==', false),
        orderBy('order', 'asc')
    );
    const snapshot = await getDocs(mQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            _id: doc.id
        }
    });
    console.log('rows = ', rows)
    return { snapshot, rows };

}


export const fetchDishesForPick = async (searchInput, categoryId = '') => {
    const cl = collection(db, 'dishes');

    let baseQuery = query(cl, orderBy('categoryId', 'asc'), orderBy('order', 'asc'), orderBy('id', 'asc'));

    if (searchInput.trim() !== '') {
        baseQuery = query(
            cl,
            where('name', '>=', searchInput),
            where('name', '<', searchInput + '\uf8ff'),
            orderBy('name', 'asc'), // Order results based on the search field
        );
    }
    if (categoryId.trim() !== '') {
        baseQuery = query(baseQuery, where('categoryId', '==', categoryId));
    }

    const snapshot = await getDocs(baseQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            _id: doc.id
        }
    });

    const rowsWrapped = rows.map(row => {
        //find same name of row rows, then compare price, smaller is will be set field 'type' = 'small', else 'large'
        const sameNameRows = rows.filter(r => r.name === row.name);
        if (sameNameRows.length > 1) {
            const sameNameRowsPrices = sameNameRows.map(r => r.price);
            const minPrice = Math.min(...sameNameRowsPrices);
            row.variationName = row.price === minPrice ? 'Small' : 'Large';
        } else {
            row.variationName = 'Regular';
        }

        return row
    })
    console.log('rows = ', rowsWrapped)
    return { snapshot, rows: rowsWrapped };

}

export const fetchModifierOfDish = async (dishId) => {
    const res = {
        dish: null,
        requireOptions: [],
        customOptions: []
    }

    const dish = await fetchDishById(dishId);
    res.dish = dish;
    const modGroupColl = collection(db, 'modifierGroup');
    const modsColl = collection(db, 'modifiers');
    const mQuery = query(modGroupColl, where('id', 'in', dish.modifications));
    const resModifierGroup = await getDocs(mQuery);
    res.requireOptions = resModifierGroup.docs.filter(doc => doc.data().isSingleChoose === true).map(doc => { return { ...doc.data(), ref: doc.ref }; });
    res.customOptions = resModifierGroup.docs.filter(doc => doc.data().isSingleChoose === false).map(doc => { return { ...doc.data(), ref: doc.ref }; });
    const listRefOfModifierGroup = resModifierGroup.docs.map(doc => doc.ref);
    const resModifier = await getDocs(query(modsColl,
        where('modifierGroupRef', 'in', listRefOfModifierGroup),
        orderBy('order', 'asc')
    ));
    const modifiers = resModifier.docs.map(doc => {
        return {
            ...doc.data(),
            _id: doc.id
        }
    });

    res.requireOptions.forEach(modifierGroup => {
        modifierGroup.options = modifiers.filter(modifier => modifier.modifierGroupRef.path === modifierGroup.ref.path);
    });
    res.customOptions.forEach(modifierGroup => {
        modifierGroup.options = modifiers.filter(modifier => modifier.modifierGroupRef.path === modifierGroup.ref.path);
    });
    console.log('res = ', res)
    return res;

}


export const fetchVouchers = async (currentPage, lastVisibleStack, rowsPerPage) => {
    const cl = collection(db, 'vouchers');

    let baseQuery = query(cl,
        where('isDeleted', '==', false),
        orderBy('createdAt', 'desc'),
    );

    if (currentPage === 1) {
        baseQuery = query(baseQuery, ...(rowsPerPage > 0 ? [limit(rowsPerPage)] : []));
    } else if (currentPage > lastVisibleStack.length && lastVisibleStack.length > 0) {
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        baseQuery = query(baseQuery, startAfter(lastVisible), limit(rowsPerPage));
    } else if (currentPage > 1 && lastVisibleStack.length >= currentPage - 1) {
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        baseQuery = query(baseQuery, startAt(prevLastVisible), limit(rowsPerPage));
    }

    const snapshot = await getDocs(baseQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('rows = ', rows)
    return { snapshot, rows };

}

export const fetchVoucherById = async (voucherId) => {
    const cl = doc(db, 'vouchers', voucherId);

    const docSnap = await getDoc(cl);
    if (docSnap.exists()) {
        return {
            ...docSnap.data(),
            id: voucherId
        }
    } else {
        return null;
    }
}

export const deleteVoucher = async (voucherId) => {
    const cl = doc(db, 'vouchers', voucherId);
    try {
        await updateDoc(cl, { isDeleted: true });
        return true;
    } catch (error) {
        console.error("Error deleting voucher:", error);
        throw error;
    }
}

export const createVoucher = async (voucher) => {
    try {
        const cl = collection(db, 'vouchers');
        const ref = await addDoc(cl, voucher);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error create voucher:", error);
        throw error;
    }
};

export const updateVoucher = async (voucher) => {
    try {
        // const 
        // const cl = collection(db, 'vouchers',);
        // const ref = await addDoc(cl, voucher);
        const ref = doc(db, 'vouchers', voucher.id);
        await updateDoc(ref, voucher);

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error create voucher:", error);
        throw error;
    }
};