import AuthContext from 'context/AuthContext';
import { Timestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { sendGiftBalanceFromAdmin } from 'services/adminService';
import { sendGiftBunnyFromAdmin } from 'services/ratingService';
import { showSuccessNotification, USDollar } from 'utils/utils';

const UserProfileCard = ({ customer, reloadCallback }) => {
    const { user } = useContext(AuthContext)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null); // 'balance' or 'bunnies'
    const [inputValue, setInputValue] = useState(0);

    const openModal = (type) => {
        setModalType(type);
        setInputValue(0);
        setIsModalOpen(true);
    };

    const handleConfirm = async () => {
        if (modalType === 'balance') {
            // Update balance
            await sendGiftBalanceFromAdmin(user.uid, customer.id, Number.parseFloat(inputValue));
            showSuccessNotification(`Sent ${USDollar.format(inputValue)} dollars for customer ${customer.name}`);
        } else {
            // Update bunnies
            await sendGiftBunnyFromAdmin(user.uid, customer.id, Number.parseInt(inputValue));
            showSuccessNotification(`Sent ${inputValue} bunnies for customer ${customer.name}`);
        }
        console.log(`Updated ${modalType}: ${inputValue} for customer ${customer.name}`);
        setIsModalOpen(false);
        reloadCallback();
    };
    return (
        <div className="mx-auto p-6 bg-white shadow rounded-lg">
            <div className="flex flex-col lg:flex-row gap-6">
                {/* Left Section - Account Data */}
                {customer && <div className="flex-1 p-6 rounded-lg shadow-sm">
                    {/* <h2 className="text-2xl font-semibold mb-4">customer data</h2> */}
                    <div className="flex items-start gap-4">
                        {/* customer Avatar */}
                        {/* <img
                            src="https://randomcustomer.me/api/portraits/men/32.jpg"
                            alt="customer Avatar"
                            className="w-24 h-24 rounded-full object-cover"
                        /> */}
                        <div>
                            {/* customer Info */}
                            {/* <span className="bg-blue-200 text-blue-800 text-xs font-semibold px-2 py-1 rounded">Essentials</span> */}
                            <h3 className="text-xl mt-2"><strong>Name: </strong> {customer.name}</h3>
                            <div className="mt-4">
                                <div className="text-sm mb-2">
                                    <strong>Email Address:</strong> {customer.email}</div>
                                <div className="text-sm mb-2">
                                    <strong>Address:</strong> {customer.address}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Registered:</strong> {new Timestamp(customer?.created_at?.seconds, customer?.created_at?.nanoseconds).toDate().toLocaleString() ?? ''}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Balance:</strong> {USDollar.format(customer.balance / 100)}
                                    <button
                                        onClick={() => openModal('balance')}
                                        className="ml-5 text-gray-700 font-semibold border border-gray-300 px-4 py-2 rounded-md focus:outline-none">
                                        + Add balance</button>
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Bunnies:</strong> {customer.bunnies ?? 0}
                                    <button
                                        onClick={() => openModal('bunnies')}
                                        className="ml-5 text-gray-700 font-semibold border border-gray-300 px-4 py-2 rounded-md focus:outline-none">
                                        + Add bunnies</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Edit Button */}
                    <div className="mt-6">
                        {/* <button className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md">
                            <svg
                                className="w-4 h-4 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 17l-5 5m0 0l-5-5m5 5V12a9 9 0 0118 0v3"
                                />
                            </svg>
                            Edit profile data
                        </button> */}
                    </div>
                </div>}
            </div>

            {/* Modal Dialog */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-semibold mb-4">
                            {modalType === 'balance' ? 'Add Balance' : 'Add Bunnies'}
                        </h2>
                        <input
                            type="number"
                            className="w-full p-2 border border-gray-300 rounded-md"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="px-4 py-2 bg-gray-300 rounded-md mr-2">
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirm}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserProfileCard;
