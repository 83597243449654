import { getFirestore, collection, getDocs, orderBy, limit, query, where, getDoc, doc, startAfter, startAt, onSnapshot, Timestamp, updateDoc, addDoc, writeBatch, and } from 'firebase/firestore';
import { app } from '../services/firebase';

const db = getFirestore(app);

export const subscribeOrderByStore = (selectedStoreAddress, rowsPerPage, onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        and(
            where('cart.shopAddress', '==', selectedStoreAddress),
            where('isDeleted', '!=', true)
        ),
        orderBy('createdAt', 'desc'),
        limit(rowsPerPage)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const subscribeOrderAllStore = (rowsPerPage, onOrdersChange) => {
    const ordersCollection = collection(db, 'orders');
    const ordersQuery = query(
        ordersCollection,
        where('isDeleted', '!=', true),
        orderBy('createdAt', 'desc'),
        limit(rowsPerPage)
    );

    // Use onSnapshot to subscribe to real-time updates
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const ordersList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        // Pass the updated ordersList to the callback
        onOrdersChange(ordersList);
    });

    // Return the unsubscribe function to allow for cleanup
    return unsubscribe;
};

export const migrationIsDeletedField = async () => {
    try {
        const col = collection(db, 'orders');
        const snapshot = await getDocs(col);
        const batch = writeBatch(db);
        snapshot.forEach((doc) => {
            const data = doc.data();
            if (data.isDeleted === undefined) {
                batch.update(doc.ref, { isDeleted: false });
            }
        });
        await batch.commit();
        console.log("Migration completed successfully.");

    } catch (error) {
        console.error("Error migration isDeleted field:", error);
    }
}

export const deleteOrder = async (orderId) => {
    try {
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
            isDeleted: true,
            updatedAt: Timestamp.now()
        });
        console.log(`Order ${orderId} deleted successfully.`);
    } catch (error) {
        console.error("Error deleting order:", error);
    }

}
export const updateOrderStatus = async (order, newStatus) => {
    try {
        const orderId = order.id
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
            orderStatus: newStatus,
            updatedAt: Timestamp.now()
        });


        if (newStatus === 'COMPLETED' && orderId != null && await checkBunnyTransactionOrder(orderId)) {
            //create bunnies transaction
            if (order.cart.totalAmount >= 5) {
                const transactionBunnies = {
                    amount: Math.floor(order.cart.totalAmount * 10),
                    uid: order.customerModel.uid,
                    type: 'ORDER',
                    status: 'RETURNED',
                    created_at: Timestamp.now(),
                    sourceDishId: null,
                    sourceOrderId: orderId,
                    sourceRatingId: null,
                    sourceReferralId: null,
                    updated_at: Timestamp.now()
                }
                //approved
                console.log('approved: ', transactionBunnies)
                const newTransactionId =
                    await saveBunnyTransaction(transactionBunnies);
                await saveTransactionBunniesReference(transactionBunnies.amount,
                    newTransactionId, order.customerModel.uid);
            }
        }
        console.log(`Order ${orderId} status updated to ${newStatus}`);
    } catch (error) {
        console.error("Error updating order status:", error);
    }
}

export const saveTransactionBunniesReference = async (bunnyTransactionAmount, newTransactionId, uid) => {
    try {
        // Get the user document reference
        const userRef = doc(db, 'users', uid);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            throw new Error(`User with uid ${uid} does not exist`);
        }

        // Retrieve user data and update fields
        const userModel = userSnapshot.data();

        // Extract current bunnies balance and bunny transactions
        const currentBunniesBalance = userModel.bunnies || 0;
        const bunnyTransactions = userModel.bunniesTransactions || [];

        // Update the user document
        await updateDoc(userRef, {
            bunnies: Number.parseInt(currentBunniesBalance) + bunnyTransactionAmount,
            bunniesTransactions: [...bunnyTransactions, newTransactionId], // Add new transaction ID to the list
            updated_at: Timestamp.now() // Use the current date and time
        });

        console.log('Transaction reference saved successfully.');
    } catch (error) {
        console.error("Error saving transaction reference:", error);
    }
};

const saveBunnyTransaction = async (request) => {
    try {
        // Add the request data to the "bunnies" collection
        const bunniesRef = collection(db, 'bunnies');
        const ref = await addDoc(bunniesRef, request);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error saving bunny transaction:", error);
        throw error;
    }
};

const checkBunnyTransactionOrder = async (orderId) => {
    const cl = collection(db, 'bunnies')
    const q = query(cl, where('sourceOrderId', '==', orderId))
    const bunnyTransaction = await getDocs(q)
    return bunnyTransaction.empty;
}

export const fetchOrderById = async (orderId) => {
    const ordersCollection = doc(db, 'orders', orderId);
    try {
        const docSnap = await getDoc(ordersCollection);
        if (docSnap.exists()) {
            const orderData = docSnap.data()
            console.log('orderData.customerModel.uid = ', orderData.customerModel.uid)
            const customerData = await fetchCustomerInfo(orderData.customerModel.uid)
            return {
                ...orderData,
                customer: customerData,
                id: orderId
            }
        } else {
            console.log("No such document!");
            return null
        }
    } catch (err) {
        console.log('fetchOrderById err: ', err)
    }
}

export const fetchCustomerInfo = async (customerId) => {
    const cl = doc(db, 'users', customerId)
    try {
        const docSnap = await getDoc(cl);
        if (docSnap.exists()) {
            return docSnap.data()
        } else {
            return null;
        }
    } catch (err) {
        console.log('fetchCustomerInfo : ', err)
        return null
    }
    // if (docSnap.exists()) {
    //     return docSnap.data()
    // } else {
    //     console.log('Error fetch customer info')
    //     return null;
    // }
}
export const fetchOrderByStore = async (selectedStoreAddress, currentPage,
    lastVisibleStack, rowsPerPage = 10, sortColumn, sortOrder, startDate, endDate) => {
    const ordersCollection = collection(db, 'orders');
    let orderQuery;
    const conditions = [];

    if (startDate) {
        conditions.push(where('createdAt', '>=', new Date(startDate).toISOString()));
    }
    if (endDate) {
        conditions.push(where('createdAt', '<=', new Date(endDate).toISOString()));
    }

    if (currentPage === 1) {
        // Fetch the first page
        if (selectedStoreAddress === 'all') {
            conditions.push(where('isDeleted', '!=', true));
            conditions.push(orderBy(sortColumn, sortOrder));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);
            // orderQuery = query(ordersCollection,
            //     where('isDeleted', '!=', true),
            //     orderBy(sortColumn, sortOrder),
            //     // orderBy('createdAt', 'desc'),
            //     limit(rowsPerPage));
        } else {
            conditions.push(
                and(
                    where("cart.shopAddress", "==", selectedStoreAddress),
                    where('isDeleted', '!=', true),
                ));
            conditions.push(orderBy(sortColumn, sortOrder));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);
            // orderQuery = query(ordersCollection,
            //     and(
            //         where("cart.shopAddress", "==", selectedStoreAddress),
            //         where('isDeleted', '!=', true),
            //     ),
            //     orderBy(sortColumn, sortOrder),
            //     // orderBy('createdAt', 'desc'),
            //     limit(rowsPerPage));
        }
    } else if (currentPage > lastVisibleStack?.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack?.length - 1];
        if (selectedStoreAddress === 'all') {

            conditions.push(where('isDeleted', '!=', true));
            conditions.push(orderBy(sortColumn, sortOrder));
            conditions.push(startAfter(lastVisible));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);

            // orderQuery = query(ordersCollection,
            //     where('isDeleted', '!=', true),
            //     orderBy(sortColumn, sortOrder),
            //     // orderBy('createdAt', 'desc'),
            //     startAfter(lastVisible),
            //     limit(rowsPerPage));
        } else {

            conditions.push(and(
                where("cart.shopAddress", "==", selectedStoreAddress),
                where('isDeleted', '!=', true),
            ));
            conditions.push(orderBy(sortColumn, sortOrder));
            conditions.push(startAfter(lastVisible));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);

            // orderQuery = query(ordersCollection,
            //     and(
            //         where("cart.shopAddress", "==", selectedStoreAddress),
            //         where('isDeleted', '!=', true),
            //     ),
            //     orderBy(sortColumn, sortOrder),
            //     // orderBy('createdAt', 'desc'),
            //     startAfter(lastVisible),
            //     limit(rowsPerPage));
        }
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        if (selectedStoreAddress === 'all') {

            conditions.push(where('isDeleted', '!=', true));
            conditions.push(startAt(prevLastVisible));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);

            // orderQuery = query(ordersCollection,
            //     where('isDeleted', '!=', true),
            //     // orderBy('createdAt', 'desc'),
            //     startAt(prevLastVisible),
            //     limit(rowsPerPage));
        } else {

            conditions.push(and(
                where("cart.shopAddress", "==", selectedStoreAddress),
                where('isDeleted', '!=', true),
            ));
            conditions.push(startAt(prevLastVisible));
            conditions.push(limit(rowsPerPage));
            orderQuery = query(ordersCollection, ...conditions);

            // orderQuery = query(ordersCollection,
            //     and(
            //         where("cart.shopAddress", "==", selectedStoreAddress),
            //         where('isDeleted', '!=', true),
            //     ),
            //     // orderBy('createdAt', 'desc'),
            //     startAt(prevLastVisible),
            //     limit(rowsPerPage));
        }
    }

    console.log('bro address = ', selectedStoreAddress)
    const snapshot = await getDocs(orderQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('orderList = ', rows)
    return { snapshot, rows };
}

export const createOrderFromAdmin = async (order) => {
    try {
        const cl = collection(db, 'orders');
        const ref = await addDoc(cl, order);

        // Update the document with its generated ID
        await updateDoc(ref, { id: ref.id });

        // Return the document ID
        return ref.id;
    } catch (error) {
        console.error("Error create order:", error);
        throw error;
    }
};
