import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const OrderStatus = ({ currentStatus, onChangeOrderStatus }) => {
    const statusSteps = ['NEW', 'PROCESS', 'DELIVERY', 'COMPLETED'];
    const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    const handleStatusChange = (status) => {
        if (status !== currentStatus) {
            setSelectedStatus(status);
            setIsStatusChangeModalOpen(true);
        }
    };

    const confirmChangeOrderStatus = () => {
        onChangeOrderStatus(selectedStatus);
        setIsStatusChangeModalOpen(false);
    };

    const closeStatusChangeModal = () => {
        setIsStatusChangeModalOpen(false);
    };

    return (
        <div>
            <div className='flex justify-between'>
                <div className='m-5'>
                    <h2 className='font-bold'>
                        ORDER STATUS
                    </h2>
                    <h4 className='italic font-normal text-s'>
                        *Tap into the status to set order status
                    </h4>
                </div>
                <div>
                    {currentStatus !== 'CANCELLED' && <button
                        onClick={() => {
                            handleStatusChange('CANCELLED');
                        }}
                        className="justify-end mt-5 mr-5 ml-5 bg-gray-600 text-white font-semibold border border-gray-300 px-4 py-2 rounded-md focus:outline-none">
                        Cancel order
                    </button>}
                </div>
            </div>
            <ol className="m-5 space-y-4">
                {statusSteps.map((status, index) => {
                    const isActive = status === currentStatus;
                    const isCompleted = statusSteps.indexOf(currentStatus) > index;

                    return (
                        <li key={index}>
                            <div
                                onClick={() => handleStatusChange(status)}
                                className={`w-full p-4 border rounded-lg ${isCompleted
                                    ? 'text-green-700 border-green-300 bg-green-50'
                                    : isActive
                                        ? 'text-blue-700 bg-blue-100 border-blue-300'
                                        : 'text-gray-900 bg-gray-100 border-gray-300'
                                    }`}
                                role="alert"
                            >
                                <div className="flex items-center justify-between">
                                    <h3 className="font-medium">{index + 1}. {status}</h3>
                                    {isCompleted && <FontAwesomeIcon icon={faCircleCheck} />}
                                    {isActive && !isCompleted && <FontAwesomeIcon icon={faArrowRight} />}
                                </div>
                            </div>
                        </li>
                    );
                })}

                {/* <button
                    onClick={handleCancelOrder}
                    className="mt-5 text-red-400 font-semibold border border-red-400 px-4 py-2 rounded-md focus:outline-none">
                    Cancel Order
                </button> */}
                {currentStatus === 'CANCELLED' &&
                    <li key={-1}>
                        <div
                            className="w-full p-4 border rounded-lg ${'text-red-700 bg-red-100 border-red-300 dark:bg-gray-800 dark:border-red-800 dark:text-red-400"
                            role="alert">
                            <div className="flex items-center justify-between">
                                <h3 className="font-medium">CANCELLED</h3>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </li>}
            </ol>

            {isStatusChangeModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <h3 className="text-lg font-semibold mb-4">Confirm Status Change</h3>
                        <p className="mb-6">Are you sure you want to change the order status to "{selectedStatus}"?</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={closeStatusChangeModal}
                                className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md focus:outline-none">
                                No, Go Back
                            </button>
                            <button
                                onClick={confirmChangeOrderStatus}
                                className="bg-orange-500 text-white px-4 py-2 rounded-md focus:outline-none">
                                Yes, Change Status
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderStatus;