import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Pagination from 'component/Pagination';
import { fetchVouchers } from 'services/dishService';
import { USDollar } from 'utils/utils';
import { Timestamp } from 'firebase/firestore';

const VoucherTable = () => {

    const { selectedStore } = useContext(AuthContext)
    const [vouchers, setVouchers] = useState([]);

    const navigate = useNavigate()

    const [lastVisibleStack, setLastVisibleStack] = useState([]); // Stack for navigating back
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);


    useEffect(() => {
        fetchVouchers(currentPage, lastVisibleStack, rowsPerPage)
            .then(res => {
                // Store the last document in the stack for future reference
                if (currentPage > lastVisibleStack.length) {
                    setLastVisibleStack((prevStack) => [...prevStack, res.snapshot.docs[res.snapshot.docs.length - 1]]);
                }
                // Check if there is a next page
                setIsNextPageAvailable(res.snapshot.size === rowsPerPage);
                setVouchers(res.rows)
            });
    }, [selectedStore, currentPage, rowsPerPage, lastVisibleStack]);

    // useEffect(() => {
    //     fetchCategories().then(res => {
    //         setCategories(res.rows)
    //     })

    // }, []);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeLimit = (e) => {
        setLastVisibleStack(prevStack => []);
        setCurrentPage(1)
        setRowsPerPage(Number(e.target.value))
    }

    // const handleClearFilter = () => {
    //     // Reset all states
    //     // setSortColumn('createdAt');
    //     // setSortOrder('desc');
    //     setSearchInput('')
    //     setCurrentPage(1);
    //     setLastVisibleStack([]);
    // }

    // const handleSearch = (searchTerm) => {
    //     setSearchInput(searchTerm.toUpperCase());
    //     setCurrentPage(1); // Reset to the first page when searching
    //     // Fetch the dishes based on the new search input here
    //     fetchDishes(currentPage, lastVisibleStack, rowsPerPage, searchTerm.toUpperCase()).then(res => {
    //         setDishes(res.rows);
    //     });
    // };

    return (
        <>
            <div className="bg-white shadow rounded-lg p-4 overflow-x-auto">
                <div className="flex justify-between items-center mb-4">
                    {/* <input type="text" placeholder="Search order" className="w-full max-w-xs pl-4 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300" /> */}
                    <button type="button"
                        className="px-5 py-2.5 text-m font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => navigate('create')}
                    >
                        Create Voucher
                    </button>
                    <div>
                        <select className="ml-4 p-2 border rounded-md"
                            onChange={handleChangeLimit}>
                            <option value={10}>10 queries</option>
                            <option value={20}>20 queries</option>
                            <option value={50}>50 queries</option>
                        </select>
                    </div>
                </div>
                <table className="w-full text-left">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            {/* <th className="p-3 font-medium text-gray-700">TYPE</th> */}
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Image</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">COUPON</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Title</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Type</th>
                            <th className="p-3 font-medium text-gray-700 cursor-pointer">Value</th>
                            <th className="p-3 font-medium text-gray-700">Created At</th>
                            <th className="p-3 font-medium text-gray-700">Expired At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vouchers && vouchers?.map((voucher, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50"
                                onClick={() => navigate(voucher.id)}
                            >
                                {/* <td className="p-3">#{dish?.id?.substring(0, 5)}</td> */}
                                <td className="p-3">
                                    <img src={voucher.imageUrl} alt={voucher.coupon}
                                        className="w-16 h-16 rounded-md mr-4" />
                                </td>
                                <td className="p-3">{voucher?.coupon}</td>
                                <td className="p-3">{voucher?.title}</td>
                                <td className="p-3">{voucher?.couponType}</td>
                                <td className="p-3">{voucher?.couponType === 'DIRECT' ?
                                    `${USDollar.format(voucher?.couponValue)}` :
                                    `${voucher?.couponValue}%`
                                }</td>
                                <td className="p-3">{voucher?.createdAt ? new Timestamp(voucher?.createdAt?.seconds, voucher?.createdAt?.nanoseconds).toDate().toLocaleString() : ''}</td>
                                <td className="p-3">{voucher?.expiredAt ? new Timestamp(voucher?.expiredAt?.seconds, voucher?.expiredAt?.nanoseconds).toDate().toLocaleString() : ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    isNextPageAvailable={isNextPageAvailable}
                />
            </div>
        </>
    );
}
export default VoucherTable