import { getFirestore, collection, getDocs, orderBy, limit, query, where, getDoc, doc, startAfter, startAt, Timestamp, updateDoc, addDoc } from 'firebase/firestore';
import { app } from '../services/firebase';
import { isEmail } from 'utils/utils';

const db = getFirestore(app);


export const fetchSuperAdmin = async (uid) => {
    const publicManager = doc(db, 'public', 'managers')
    const docSnap = await getDoc(publicManager);
    if (docSnap.exists()) {
        return docSnap.data().super.includes(uid)
    } else {
        return false;
    }
}

export const fetchRemoteStores = async (user) => {
    const storesCollection = collection(db, 'stores');
    const storesSnapshot = await getDocs(storesCollection);
    const storesList = storesSnapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    // if (process.env.NODE_ENV !== 'production') {
    //     storesList = storesList.filter(store => store?.title?.includes('Test') === false);
    // }
    if (user.isSuper) {
        return storesList
    }

    //check privilege
    const publicManager = doc(db, 'public', 'managers')
    const currentEmail = user.email;
    try {
        const docSnap = await getDoc(publicManager);
        if (docSnap.exists()) {
            const stores = docSnap.data().stores
            const allowedStoreIds = getAllowedStore(currentEmail, stores);
            const allowedStores = storesList.filter(store => allowedStoreIds.includes(store.id))
            return allowedStores
        } else {
            return null;
        }
    } catch (err) {
        return null
    }
}

const getAllowedStore = (email, stores) => {
    const allowedStores = []
    console.log(stores)
    for (const key in stores) {
        if (stores.hasOwnProperty(key)) {
            if (stores[key].includes(email)) {
                allowedStores.push(key)
            }
        }
    }
    return allowedStores
}

export const fetchUsers = async (currentPage, lastVisibleStack, usersPerPage, searchInput, sortColumn, sortOrder) => {
    const cl = collection(db, 'users');
    let mQuery;
    if (currentPage === 1) {
        // Fetch the first page
        mQuery = query(cl, orderBy(sortColumn, sortOrder), limit(usersPerPage));
    } else if (currentPage > lastVisibleStack.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAfter(lastVisible), limit(usersPerPage));
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        mQuery = query(cl, orderBy(sortColumn, sortOrder), startAt(prevLastVisible), limit(usersPerPage));
    }

    const searchField = isEmail(searchInput) ? "email" : "name";

    if (searchInput) {
        mQuery = query(cl,
            where(searchField, '>=', searchInput),
            where(searchField, '<', searchInput + '\uf8ff'),
            // orderBy(sortColumn, sortOrder),
            limit(usersPerPage)
        );
    }

    const snapshot = await getDocs(mQuery);
    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });
    console.log('users = ', rows)
    return { snapshot, rows };

}

export const fetchTransactions = async (currentPage, lastVisibleStack, rowsPerPage, startDate, endDate) => {
    const cl = collection(db, 'transactions');
    let mQuery;
    const conditions = [];

    if (startDate) {
        conditions.push(where('created', '>=', Math.round(new Date(startDate).getTime() / 1000)));
    }
    if (endDate) {
        conditions.push(where('created', '<=', Math.round(new Date(endDate).getTime() / 1000)));
    }

    if (currentPage === 1) {
        // Fetch the first page
        conditions.push(orderBy('created', 'desc'))
        conditions.push(limit(rowsPerPage))
        mQuery = query(cl, ...conditions);
    } else if (currentPage > lastVisibleStack.length) {
        // Fetch the next page after the last visible document of the previous page
        const lastVisible = lastVisibleStack[lastVisibleStack.length - 1];
        conditions.push(orderBy('created', 'desc'));
        conditions.push(startAfter(lastVisible));
        conditions.push(limit(rowsPerPage));
        mQuery = query(cl, ...conditions);
        // mQuery = query(cl, orderBy('created', 'desc'), startAfter(lastVisible), limit(rowsPerPage));
    } else {
        // Fetch the previous page using the document at the start of the previous page
        const prevLastVisible = lastVisibleStack[currentPage - 2];
        conditions.push(orderBy('created', 'desc'));
        conditions.push(startAt(prevLastVisible));
        conditions.push(limit(rowsPerPage));
        mQuery = query(cl, ...conditions);
        // mQuery = query(cl, orderBy('created', 'desc'), startAt(prevLastVisible), limit(rowsPerPage));
    }

    const snapshot = await getDocs(mQuery);

    const rows = snapshot.docs.map(doc => {
        return {
            ...doc.data(),
            id: doc.id
        }
    });

    const uids = rows.map(row => row.uid).filter(uid => uid !== undefined); // Filtering out undefined values if any
    const users = await fetchUsersByIds(uids)
    console.log('users = ', users)

    const rowsMapped = rows.map(row => {
        const rowUser = users.find(u => u.id === row.uid)
        return {
            ...row,
            userInfo: rowUser
        }
    })

    console.log('rows = ', rowsMapped)
    return { snapshot, rows: rowsMapped };

}

export const fetchUsersByIds = async (userIds) => {
    if (!userIds || userIds.length === 0) {
        return []; // Return an empty array if no user IDs are provided
    }

    try {
        // Create a query to get users whose document IDs are in the userIds array
        const usersCollection = collection(db, 'users');
        const usersQuery = query(usersCollection, where('__name__', 'in', userIds));

        // Execute the query
        const querySnapshot = await getDocs(usersQuery);

        // Map through the query results and extract user data
        const users = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        console.log('Fetched Users:', users);
        return users;
    } catch (error) {
        console.error("Error fetching users by IDs:", error);
        return [];
    }
};

export const fetchUserById = async (userId) => {
    if (!userId || userId.length === 0) {
        return null;
    }
    const userRef = doc(db, 'users', userId);
    try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists) {
            // Map through the query results and extract user data

            // 2. Get order references
            const orderReferences = userSnap.data().orders || []; // Assuming 'orders' is an array of DocumentReferences
            // Get the last 10 orders (if the length is less than 10, get all of them)
            const lastOrderRefs = orderReferences.reverse();
            // 3. Fetch the last three orders
            const orderPromises = lastOrderRefs.map((orderRef) => getDoc(orderRef));
            const orderSnapshots = await Promise.all(orderPromises);
            // Convert snapshots to data
            const orders = orderSnapshots.map((orderSnap) => ({
                ...orderSnap.data(),
                id: orderSnap.id,
            }));


            //transactions
            const transactionReferences = userSnap.data().transactions || [];
            const lastTransactionRefs = transactionReferences.reverse();
            const transactionsPromise = lastTransactionRefs.map(transaction => getDoc(doc(db, 'transactions', transaction)))
            const transactionsSnapshot = await Promise.all(transactionsPromise);
            const transactions = transactionsSnapshot.map(transactionSnap => ({
                ...transactionSnap.data(),
                id: transactionSnap.id,
            }))

            const user = {
                ...userSnap.data(),
                id: userId,
                recentOrders: orders,
                recentTransactions: transactions,
            }


            console.log('Fetched User:', user);
            return user;
        } else {

            console.log("No such document!");
            return null
        }

    } catch (error) {
        console.error("Error fetching user by ID :", error);
        return null;
    }
};

export const sendGiftBalanceFromAdmin = async (adminUID, customerUID, giftAmount) => {

    /*
amount 100 (number)
created 1732507594 (number)
id "pi_3QOtZGE3RLzHKyNA1fXnetTR" (string)
payment_method_types (array) 0 "card" (string)
sourceGiftCardId null (null)
sourceOrderId null (null)
type "TOPUP" (string)
uid "s3lRcp8jftaOdIDzxYELTRnJ8Vz1" (string)
*/
    const transaction = {
        amount: giftAmount * 100,
        created: Math.round(Date.now() / 1000),
        id: null,
        payment_method_types: null,
        sourceGiftCardId: null,
        sourceOrderId: null,
        type: 'TOPUP',
        uid: customerUID,
        createdBy: adminUID,
    }
    console.log(transaction);

    //saveTransaction
    const newTransactionId = await saveTransaction(transaction);
    //saveTransactionReference
    await saveTransactionReference(transaction, newTransactionId);
}


const saveTransaction = async (transactionData) => {
    const cl = collection(db, 'transactions');
    const newTransaction = await addDoc(cl, transactionData);
    return newTransaction.id;
}

const saveTransactionReference = async (transactionData, transactionId) => {
    const customerUid = transactionData.uid;
    const docSnap = await getDoc(doc(db, 'users', customerUid));
    if (docSnap.exists()) {
        const userTransactions = docSnap.data().transactions || [];
        const currentBalance = Number.parseInt(docSnap.data().balance || 0);
        //update user orders
        console.log('currentBalance + (transactionData.amount || 0) = ', currentBalance + (transactionData.amount || 0))
        console.log('transactionid = ', transactionId)
        await updateDoc(doc(db, 'users', customerUid), {
            balance: currentBalance + (transactionData.amount || 0),
            transactions: [...userTransactions, transactionId],
            updatedAt: Timestamp.now(),
            updated_at: Timestamp.now(),
        });
    }

}
